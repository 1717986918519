import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import CatchImg from 'images/blog-images/catch.png';
import SouthKoreanShipping from 'images/blog-images/shipkoo-shipping-south-korea.png';
import GmarketFeatured from 'images/featured-image/gmarket-featured.png';
import Gmarket from 'images/blog-images/gmarket.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query GmarketImg {
      file(relativePath: { eq: "gmarket.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="gmarket ranked 1st in NBCI"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Gmarket Ranked 1st in the NBCI eCommerce Field | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-gmarket" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Gmarket Ranked 1st in the NBCI eCommerce Field",
              content: intl.formatMessage({ id: "blog-post-meta-title-gmarket" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: GmarketFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="gmarket-ranked-1st-in-the-nbci-ecommerce-field"
        title="Gmarket Ranked 1st in the NBCI eCommerce Field"
        date="2020-11-12"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <p>
              According to Korean media reports, Gmarket once again won the 2020 National Brand Competitiveness Index (NBCI: National Brand Competitiveness Index) online shopping champion, sponsored by the Korea Productivity Center, KPC. It is reported that this is the 14th time Gmarket has won this award. The National Brand Competitiveness Index is an index that ranks related brands or companies in the field by indexing selected brand image and indicators such as recognition and loyalty. Gmarket "changes (consumer) shopping methods", based on the slogan of, in the era of the epidemic, a variety of services that facilitate consumers’ shopping have been continuously launched, which has won unanimous praise from the market.
            </p>

            <Image src={Gmarket} className="" alt="gmarket ranked !st in NBCI"/>

            <p>
              "New Life of the Month", which Gmarket first launched in February this year, is a good example. This activity integrates the service industry into online consumption through strategic cooperation with tertiary industry sellers/enterprises close to consumers' lives, and strengthens product competitiveness. Coupons launched in cooperation with mobile refueling discount platform owin, household cleaning services and games are all sub-projects of the project. With the advent of ontech (online + contactless shopping) shopping era, Gmarket, which has adopted the correct strategy, has entered the fast lane of development.
            </p>

            <Image src={CatchImg} className="" alt="catch service"/>

            <p>
              The "캐치" (Catch) service that has grasped the latest trends and directly recommends products to consumers is also very popular. The project uploads more than 10 themes every week, monthly, number, and gold. Because the latest hot issues will be prompted, the traffic accessed through ‘캐치’ will increase by about 50% every month. Creative products such as "creative daily necessities worth around 10,000 won" and "sleep pillows" are very popular in the market. 
            </p>

            <p>
              In addition, the rapid pace of new brands and manufacturers also brings fun to consumers. Gmarket also introduced the "onnuri electronic merchandise voucher settlement system" that can purchase more than 1,600 traditional market commodities. This means that Gmarket has devoted more effort than ever to help small and micro sellers who are in difficulty due to the plummeting sales caused by the epidemic.
            </p>

            <Image src={SouthKoreanShipping} alt="shipcoo shipping south korea" />

            <p>
              Shipkoo is continuously working on developing new shipping routes and providing various shipping options, which is the only way to ensure your products can reach your customers in time. One of our signature routes, South Korea Express Shipping is welcome by many of our new customers. It only takes 3-5 days to ship from China to South Korea, your product is able to reach your customer promptly with starting from $3.7 per item. <Link to="/contact">Contact us</Link> today and get your customized quote.
            </p>


            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;